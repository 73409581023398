<template>
  <section>
    <div>
      <div class="columns is-multiline is-gapless">
          <div class="column is-4">
             <img src="@/assets/img/p4.webp" class="is-block width100" style="height:100%">
          </div>
          <div class="column is-4">
             <img src="@/assets/img/p2.webp" class="is-block width100" style="height:100%">
          </div>
          <div class="column is-4">
             <img src="@/assets/img/p3.webp" class="is-block width100" style="height:100%">
          </div>
        </div>
      </div>
  </section>
</template>

<script>
export default {
  name: 'Slider',
  data () {
    return {
        progress: true,
        progressType: 'is-primary',
        images: [
            {
            icon: 'check-circle',
            title: 'Calidad',
            subtitle: 'La calidad de los materiales utilizados asegura un rendimiento excelente del producto',
            bg: 'bgFistSection2'
            },
            {
            icon: 'dollar-sign',
            title: 'Precio competitivo',
            subtitle: 'Cada producto está fabricado para tener una increíble duración',
            bg: 'bgFistSection1'
            },
            {
            icon: 'box',
            title: 'Variedad',
            subtitle: 'Nuestro envío alcanza todo el país y asegura que sea entregado en un estado perfecto',
            bg: 'bgFistSection3'
            }
        ]
    }
  }
}
</script>

<style lang="sass">

.bgFistSection1
    width: 100%
    min-height:400px
    background-size: cover
    background-image: url("../../assets/img/separatorImg2.jpg")

.bgFistSection2
    width:100%
    min-height:400px
    background-size: cover
    background-image: url("../../assets/img/separatorImg1.jpg")

.bgFistSection3
    width:100%
    min-height:400px
    background-size: cover
    background-image: url("../../assets/img/separatorImg3.jpg")

</style>
