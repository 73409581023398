<template>
  <section>
    <b-carousel :indicator="false">
      <b-carousel-item>
        <section :class="`hero sliderHeightPadding bg-img1 is-bold`">
          <div class="columns is-multiline">
              <div class="column is-8 mx-auto is-block">
                <img src="@/assets/img/slider/hambur.png" style="max-width: 300px"> 
                <h1 class="titleBig3 has-text-white fontWeightBold fontMoreBlack has-text-center scale-in-center fontPrimary mb-0">
                  HAMBURGUESAS Y MEDALLONES
                </h1>
                <h2 class="is-size-4-tablet is-size-4-mobile has-text-white has-text-center
                fontUpperCase scale-in-center fontPrimary mt-2 fontWeightBlack letterSpacingShort">
                  Venta mayorista
                </h2>
                <h2 class="has-text-white has-text-center bgColorBlack p-4 scale-in-center fontPrimary mt-2 letterSpacingShort is-hidden-tablet"
                style="max-width:300px;display:block;margin:auto;border-radius:22px">
                  Solo comercios y supermecados
                </h2>
                <h2 class="bgColorPrimary pt-3 pb-3 has-text-white is-size-4 fontUpperCase fontWeightBlack
                has-text-center scale-in-center fontPrimary mt-2 letterSpacingShort is-hidden-mobile"
                style="max-width:600px;display:block;margin:auto;border-radius:22px">
                  Solo para comercios y supermecados
                </h2>
              </div>
          </div>

        </section>
      </b-carousel-item>
    </b-carousel>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'Slider',
    data () {
      return {
          param: '',
          progress: true,
          progressType: 'is-primary',
          carousels: [
              {
              background: 'img1'
              },
              {
              background: 'img2'
              }
          ]
      }
    },
    created () {
      this.catchParams()
      console.log(this.$route.query)
    },
    computed: {
      ...mapState('brand', ['brand'])
    },
    methods: {
      catchParams () {
        this.param = this.$route.query
    },
    getDoor () {
      window.location.href=`#productos`
    },
  }
}
</script>

<style lang="sass">
@import '../../assets/css/variables'

.bg-img1
    width: 100%
    background-size: cover
    background-repeat: no-repeat
    background-image: url("../../assets/img/slider/portada-nueva.jpg")
    @media (max-width: 470px)
        background-image: url("../../assets/img/slider/sliderMobil1.jpg")
        background-size: cover
</style>

