<template>
  <div id="app" style="overflow:hidden">
    <b-navbar class="bgColorWhite" :shadow="true" pause-info="false"  pause-text="2" pause-info-type="is-dark" fixed-top>
    <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
            <img src="@/assets/img/isologo.png"
            class="is-hidden-tablet"
            > 
        </b-navbar-item>
    </template>        
      <template slot="start">
        <img src="@/assets/img/isologo.png"
        class="fade-in logo is-hidden-mobile"
        > 
      </template>
      <template slot="end">
          <div class="buttons">
            <a :href="brand.whatsappUrl" >
              <b-button class="button3d bgColorSecondary fontWeightBold fontUpperCase letterSpacingShort has-text-white noBorder width100 pr-4">
                <img src="@/assets/img/whatsapp.webp" class="whatsappTopButton">
                <p style="display:inline-block;margin-left:5px;position:relative;bottom:3px">CONSULTAR</p>
              </b-button>
            </a>
          </div>
      </template>
    </b-navbar>
    <router-view/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data () {
        return {
            items: [
            {   
                title: 'Productos',
                icon: 'snowflake',
                to: { name: '#productos' }
            },
            {
                title: 'Distribuidores',
                icon: 'check-circle',
                to: { name: '#distri' }
            },
            {
                title: 'Contacto',
                icon: 'envelope',
                to: { name: '#contacto' }
            }
            ]
        }
    },
    metaInfo() {
        return {
            title: "Alimentos congelados",
        }
    },
    computed: {
        ...mapState('brand', ['brand'])
    }
}

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
