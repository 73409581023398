<template>
  <div>
    <div class="columns pl-2 pr-2 pb-1" :class="bgSeparator">
      <div class="column is-12">
        <h1 class="has-text-centered letterSpacingShort is-size-2 lineHeight2">
          <span :class="titleOneColor" v-if="titleOne">
            {{ titleOne }}
          </span>
          <span :class="titleTwoColor" v-if="titleTwo">
            {{ titleTwo }}
          </span>
          <span :class="titleThreeColor" v-if="titleThree">
            {{ titleThree }}
          </span>
        </h1>
        <div class="is-block m-2"  v-if="subtitleOne || subtitleTwo">
          <p
            class="mb-none mt-2  mx-auto p-2 | has-text-centered letterSpacingShort fontWeight is-size-4"
            v-show="subtitleOne.length > 2"
            :class="subtitleColor"
          >
            {{ subtitleOne }}
          </p>
          <p
            class="mb-2 mt-1  mx-auto p-2 | has-text-centered letterSpacingShort fontWeight is-size-4"
            :class="subtitleColor"
            v-show="subtitleTwo.length > 2"
          >
            {{subtitleTwo}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Separator',
  props: ['titleOne', 'titleOneColor',
          'titleTwo', 'titleTwoColor',
          'titleThree', 'titleThreeColor',
          'subtitleOne', 'subtitleTwo', 'subtitleColor',
          'bgSeparator']
}
</script>

<style>

</style>
