<template>
  <section>
      <div class="columns is-multiline" style="overflow:none!important">
            
    </div>
  </section>
</template>

<script>
export default {
  name: 'Slider',
  data () {
    return {
        progress: true,
        progressType: 'is-primary',
    }
  }
}
</script>
