
<template>
  <section>
    <div>
      <div class="columns is-multiline is-gapless ">
        <div class="column is-12">
          <div class="columns">
            <div class="column is-6 centerBlock">
                <img src="@/assets/img/yamu.webp" class="is-block width100 is-hidden-tablet">
                <h1 class="is-size-1-tablet is-size-2-mobile has-text-black pl-2 pr-2 fontWeightBold fontMoreBlack has-text-center scale-in-center fontPrimary mb-0 mtBurguer-7">
                  Atención a:
                </h1>
                <div class="ml-5 mr-5 mb-5">
                  <p class="is-size-3 fontWeightBlack mt-5 bgColorPrimary has-text-white p-2">Almacenes</p>
                  <p class="is-size-3 fontWeightBlack mt-4 bgColorPrimary has-text-white p-2">Distribuidoras</p>
                  <p class="is-size-3 fontWeightBlack mt-4 bgColorPrimary has-text-white p-2">Supermercados</p>
                </div>
                
                  <h2 class="is-size-2-tablet is-size-3-mobile fontWeightBlack has-text-black has-text-center fontUpperCase scale-in-center fontPrimary mt-0">
                    Elegirnos, es elegir bien.
                  </h2>
              </div>
          </div>
        </div>
        <div class="column is-6 is-hidden">
                <img src="@/assets/img/facu.webp" class="is-block width100 mt-7 is-hidden-tablet">
                <h1 class="is-size-1-tablet is-size-2-mobile has-text-black pl-2 pr-2 fontWeightBold fontMoreBlack has-text-center scale-in-center fontPrimary mb-0 mtBurguer-7">
                  Atención a:
                </h1>
                <div class="ml-5 mr-5 mb-5">
                  <p class="is-size-3 fontWeightBlack mt-5 bgColorPrimary has-text-white p-2">Consumidor final</p>
                  <p class="is-size-3 fontWeightBlack mt-4 bgColorPrimary has-text-white p-2">Familias</p>
                  <p class="is-size-3 fontWeightBlack mt-4 bgColorPrimary has-text-white p-2">Cumpleaños y fiestas</p>
                </div>
                
                  <h2 class="is-size-2-tablet is-size-3-mobile fontWeightBlack has-text-black has-text-center fontUpperCase scale-in-center fontPrimary mt-0">
                    Cada día, trabajamos para vos.
                  </h2>
        </div>
      </div>
    </div><br><br><br> <br>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Slider',
  data () {
    return {
        progress: true,
        progressType: 'is-primary'
    }
  },
   computed: {
    ...mapState('brand', ['brand'])
  },
  methods: {
      getDoor () {
        window.location.href=`https://wa.me/541158238849?text=Hola! Me comunico desde la web quiero solicitar la Puerta Estrella: Punta Diamante Doorwind`
      },
      getDoor2 () {
        window.location.href=`https://wa.me/541158238849?text=Hola! Me comunico desde la web quiero solicitar la Puerta Placa`
      },
      getDoor3 () {
        window.location.href=`https://wa.me/541158238849?text=Hola! Me comunico desde la web quiero solicitar la Puerta Corrediza de cedro`
      }
  }
}
</script>
