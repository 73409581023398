<template>
  <footer>
    <div class="container-fluid bgColorBlack">   
    <iframe
      class="is-hidden"
      src=""
      width="100%"
      height="450"
      frameborder="0"
      style="border:0;line-height: 0px!important"
      allowfullscreen=""
      aria-hidden="false"
      tabindex="0"></iframe>
      <div class="container">
        <div class="columns is-multiline">
              <div class="column is-4-tablet is-12-mobile paddingMobileFooter">
                <p class="is-size-4-tablet colorSoftGrey fontSecondary  letterSpacingShort is-inline-block mt-6 mb-2">
                    {{brand.name}}
                </p>
                <br>
                <p class="subtitle is-6 has-text-white mb-2 pr-4 lineHeight1">
                 {{brand.slogan}}
                </p>

                <br>
              </div>
              <div class="column is-4-tablet is-12-mobile paddingMobileFooter">
                <p class="is-size-4-tablet colorSoftGrey fontSecondary letterSpacingShort is-inline-block mt-6 mb-2">
                    Redes
                </p>
                <p class="subtitle is-6 has-text-white mb-2 pr-4 lineHeight1">
                    <a :href="brand.urlIG" target="_blank"
                    class="has-text-white">
           
                    <img src="@/assets/img/icons/instagramWhite.png" width="16px" class="mr-2 iconPositionFooter">
                      Instagram
                    </a>
                </p>
                <p class="subtitle is-6 has-text-white mb-2 pr-4 lineHeight1">
                  <a :href="brand.urlFB" target="_blank"
                      class="has-text-white">
                      <img src="@/assets/img/icons/facebookWhite.png" width="16px" class="mr-2 iconPositionFooter">
                        Facebook
                  </a>
                </p>

              </div>
              <div class="column is-4-tablet is-12-mobile paddingMobileFooter">
                <p class="is-size-4-tablet colorSoftGrey fontSecondary  letterSpacingShort is-inline-block mt-6 mb-2">
                    Contacto
                </p>  
                <p class="subtitle is-6 has-text-white mb-2 pr-4 lineHeight1">
                Whatsapp 
                <a :href="`https://api.whatsapp.com/send?phone=${brand.whatsappUrl}`" class="colorPrimary" target="_blank" style="color:#FFF!important">{{ brand.phone }}</a>
                </p>



                <br>
              </div>
              <div class="column is-12">
                <p class="has-text-centered subtitle is-7 has-text-white pb-6">
                  Sitio web desarrollado por <a href="https://www.cmlaplata.com.ar" class="has-text-white">CM La Plata</a>
                </p>
              </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Footer',
  computed: {
    ...mapState('brand', ['brand'])
  },
    methods: {
      goWhatsapp () {
        window.location.href=`https://api.whatsapp.com/send?phone=${this.brand.phone}`
      }
  }
}
</script>
