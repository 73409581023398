<template>
  <div class="container-fluid p-5 bgColorSecondary">
    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <h1
            class="is-size-1 has-text-centered has-text-white fontWeightBlack fontMoreBlack pt-1 pb-5"
          >
            ¡Hace tu consulta!
          </h1>
          <div class="columns is-block mx-auto">
            <form class="mb-7">
              <b-field>
                <b-input
                  v-model="form.name"
                  type="text"
                  placeholder="Nombre"
                />
              </b-field>
              <b-field>
                <b-input
                  v-model="form.phone"
                  type="text"
                  placeholder="Teléfono"
                />
              </b-field>
              <b-field>
                <b-input
                  v-model="form.email"
                  type="email"
                  placeholder="Email"
                />
              </b-field>
              <b-field class="mb-0">
                <b-input
                  v-model="form.message"
                  type="textarea"
                  placeholder="Escribe tu mensaje."
                />
              </b-field>
              <b-button
                v-show="sent === false"
                class="bgColorWhite formSubmitButton width100 has-text-black fontWeightBlack p-5 is-size-4 mt-3"
                @click="sendEmailContact"
              >
                Enviar mensaje
              </b-button>
              <p v-show="sent === true" class="mt-4 has-text-twhite text-white">
                Mensaje enviado ¡Gracias por contactarnos!
              </p>
              <p v-show="completeForm === true" class="mt-4">
                Por favor <span class="has-text-danger fontWeightBlack">complete</span> todos los campos del formulario.
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
    data () {
        return {
            form: {
                name: '',
                email: '',
                phone: '',
                message: ''
            },
            sent: false,
            completeForm: false
        }
    },
    methods: {
            sendEmailContact () {
                if (this.form.name.length > 2 && this.form.phone.length > 2 && this.form.email.length > 2 && this.form.message.length > 2) {
                    axios.post('https://congeladosdelplata.com.ar/send.php', {
                                    name: this.form.name,
                                    email: this.form.email,
                                    phone: this.form.phone,
                                    message: this.form.message
                        }).then((res) => {
                        console.log(res)
                        this.sent = true
                        this.completeForm = false
                        this.form.name = ''
                        this.form.email = ''
                        this.form.phone = ''
                        this.form.message = ''
                    })
                } else {
                this.completeForm = true
                }
            }
    }
}
</script>
<style>
</style>
