<template>
  <section>
    <div>
      <div class="columns is-multiline is-gapless">
        <div
          v-for="(item, index) in images"
          :key="index"
          class="column is-4 is-12-mobile"
        >
          <b-carousel
            :indicator="false"
            :pause-info="false"
          >
            <b-carousel-item>
              <section :class="`hero ${item.bg} is-bold`">
                <div class="text-centered minHeighSeparator">
                  <div class="mt-5">
                    <font-awesome-icon :icon="item.icon" class="overParticles colorPrimary services bgColorPrimaryLight has-text-white mt-7" />
                    <h2 class="has-text-white is-size-3 fontWeightBlack fontUpperCase paddingSeparatorImages ">
                      {{ item.title }}
                    </h2>
                    <h2 :class="`has-text-white is-size-4 fontWeightRegular letterSpacingShort | p-5 is-hidden`">
                      {{ item.subtitle }}
                    </h2>
                  </div>
                </div>
              </section>
            </b-carousel-item>
          </b-carousel>
            <div class="columns bgColorPrimary is-hidden-tablet">
              <div class="column is-12">
                  <p
                    class="mx-auto p-3 | has-text-centered has-text-white  letterSpacingShort fontWeight is-size-6 fontItalic heartbeat"
                    v-if="index === 0"
                  >
                    Hechas con los mejores ingredientes
                  </p>
                  <p
                    class="mx-auto p-3 | has-text-centered has-text-white letterSpacingShort fontWeight is-size-6 fontItalic heartbeat"
                    v-if="index === 1"
                  >
                    Consultá ahora
                  </p>
                  <p
                    class="mx-auto p-3 | has-text-centered has-text-white  letterSpacingShort fontWeight is-size-6 fontItalic heartbeat"
                    v-if="index === 2"
                  >
                    Alimentos congelados
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>
</template>

<script>
export default {
  name: 'Slider',
  data () {
    return {
        progress: true,
        progressType: 'is-primary',
        images: [
            {
            icon: 'check-circle',
            title: 'Calidad',
            subtitle: 'La calidad de los materiales utilizados asegura un rendimiento excelente del producto',
            bg: 'bgFistSection2'
            },
            {
            icon: 'dollar-sign',
            title: 'Precio competitivo',
            subtitle: 'Cada producto está fabricado para tener una increíble duración',
            bg: 'bgFistSection1'
            },
            {
            icon: 'box',
            title: 'Variedad',
            subtitle: 'Nuestro envío alcanza todo el país y asegura que sea entregado en un estado perfecto',
            bg: 'bgFistSection3'
            }
        ]
    }
  }
}
</script>

<style lang="sass">

.bgFistSection1
    width: 100%
    min-height:400px
    background-size: cover
    background-image: url("../../assets/img/separatorImg2.jpg")

.bgFistSection2
    width:100%
    min-height:400px
    background-size: cover
    background-image: url("../../assets/img/separatorImg1.jpg")

.bgFistSection3
    width:100%
    min-height:400px
    background-size: cover
    background-image: url("../../assets/img/separatorImg3.jpg")

</style>
