

<template>
      <div class="container-fluid bgColorBlack pt-5 pb-5" data-aos="fade-up"><br><br>
      <div class="container pt-5 pb-5">
        <div class="columns">  
        <div class="column is-6">
                <b-carousel class="is-block mx-auto" :indicator="false" :pause-info="false">
                    <b-carousel-item >
                        <section :class="`hero is-small commentsPaddingResponsive`">
                            <div class="hero-body">
                                <div class="positionTestimonials">
                                    <i class="far fa-comments is-size-1 iconResponsivePosition"></i>
                                    <h3 class="has-text-white is-size-1 textTestimonyResponsive">
                                       Nuestros clientes
                                    </h3>
                                    <div class="separatorLine bgColorSecondary width40 mt-2 is-hidden-tablet is-block mx-auto"></div>
                                    <div class="separatorLine bgColorSecondary width40 mt-5 linePosition is-hidden-mobile"></div>
                                    <br class="is-hidden-mobile"><br class="is-hidden-mobile">
                                    <h3 class="has-text-white is-size-3 textTestimonyResponsive is-hidden-mobile">
                                        Confían en nosotros
                                    </h3>
                                </div>
                            </div>
                        </section>
                    </b-carousel-item>
                </b-carousel>
          </div>
          <div class="column is-6">
                <b-carousel class="is-block mx-auto" :pause-info="false" autoplay :pause-hover="false">
                    <b-carousel-item v-for="(carousel, i) in carouselsOpinions" :key="i">
                        <section :class="`hero is-small`">
                            <div class="hero-body has-text-centered"> 
                                <img :src="require(`../../assets/img/marcas/${carousel.img}`)"
                                class="mb-4"
                                style="width:200px;display:block;margin:auto;border-radius: 10px"> 
                                <div class="mb-2 is-hidden">
                                    <font-awesome-icon icon="star" class="iconMenu colorYellow ml-2" /> 
                                    <font-awesome-icon icon="star" class="iconMenu colorYellow ml-2" /> 
                                    <font-awesome-icon icon="star" class="iconMenu colorYellow ml-2" /> 
                                    <font-awesome-icon icon="star" class="iconMenu colorYellow ml-2" /> 
                                    <font-awesome-icon icon="star" class="iconMenu colorYellow ml-2" /> 
                                </div>
                                <h1 class="has-text-white is-size-5 fontWeightLight fontItalic is-hidden">"{{carousel.text}}"</h1>
                            </div>
                        </section>
                    </b-carousel-item>
                </b-carousel>
          </div>            
        </div> 
      </div>         <br><br>
    </div> 
</template>
<script>
export default {
    data () {
        return {
            carouselsOpinions: [
                { img:'1.jpg', color: 'primary' },
                { img:'2.jpg', color: 'primary' },
                { img:'3.jpg', color: 'primary' },
                { img:'4.jpg', color: 'primary' },
                { img:'5.jpg', color: 'primary' },
                { img:'6.jpg', color: 'primary' },
                { img:'7.jpg', color: 'primary' },
                { img:'8.jpg', color: 'primary' },
                { img:'9.png', color: 'primary' }
            ],
        }
    }
}
</script>
