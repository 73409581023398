export default {
    namespaced: true,
    state: {

        //Marca
        brand:{
            name:'Congelados del Plata',
            slogan:'Alimentos congelados, hamburguesas y medallones',
            email: 'congeladosdelplata.com.ar', 
            emailUrl: 'congeladosdelplata.com.ar',
            phone: '+54 221 4201530',

            urlWebForImgEmail: 'https://congeladosdelplata.com.ar',
            whatsappUrl: 'https://wa.me/542214201530?text=Hola! Me contacto desde la web y quiero hacer un pedido',
            phoneUrl: 'tel:+542214201530',
            urlIG:'https://www.instagram.com/congeladosdelplata',
            urlFB:'https://www.facebook.com/Congelados-Del-Plata-109903018066390'
        },

    },

    mutations: {

    },

    actions: {

    },
    getters:{
        
    }
}