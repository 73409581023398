
<template>
  <section>
    <div>
      <div class="columns is-multiline is-gapless pt-5 pb-5 mb-4">
        <div class="column is-6">
                <img src="@/assets/img/yamu.webp" class="is-block width100 is-hidden-tablet">
                <h1 class="is-size-1-tablet is-size-2-mobile has-text-black pl-2 pr-2 fontWeightBold fontMoreBlack has-text-center scale-in-center fontPrimary mb-0 mtBurguer-7">
                  <span class="fontWeightUltraBlack">Hamburguesas YAMU</span> <br>  100% carne vacuna 80 gramos
                </h1>
                <h1 class="is-size-3-tablet mt-3 is-size-4-mobile has-text-black pl-2 pr-2 fontWeightBold fontMoreBlack has-text-center scale-in-center fontPrimary mb-0">
                  Presentación:
                </h1>
                <div class="ml-5 mr-5 mb-5">
                  <p class="is-size-4 mt-5 bgColorPrimary has-text-white p-2">Cajas por 60 unidades para venta individual por 2 unidades</p>
                  <p class="is-size-4 mt-4 bgColorPrimary has-text-white p-2">Cajas por 20 unidades</p>
                  <p class="is-size-4 mt-4 bgColorPrimary has-text-white p-2">Caja Master de 12 cajas por 4 unidades</p>
                </div>
                
        </div>
        <div class="column is-6">
          <img src="@/assets/img/yamu.webp" class="is-block width100 is-hidden-mobile">
        </div>
        <div class="column is-6">
          <img src="@/assets/img/facu.webp" class="is-block width100 is-hidden-mobile">
        </div>
        <div class="column is-6">
                <img src="@/assets/img/facu.webp" class="is-block width100 mt-7 is-hidden-tablet">
                <h1 class="is-size-1-tablet is-size-2-mobile has-text-black  pl-2 pr-2  fontWeightBold fontMoreBlack has-text-center scale-in-center fontPrimary mb-0 mtBurguer-7">
                  <span class="fontWeightUltraBlack">Medallon FACU</span> <br> Burger 65 gramos
                </h1>
                <h1 class="is-size-3-tablet mt-3 is-size-4-mobile has-text-black pl-2 pr-2 fontWeightBold fontMoreBlack has-text-center scale-in-center fontPrimary mb-0">
                  Presentación:
                </h1>
                <div class="ml-5 mr-5 mb-5">
                  <p class="is-size-4 mt-5 bgColorPrimary has-text-white p-2">Cajas por 60 unidades para venta individual x 2 unidades</p>
                  <p class="is-size-4 mt-5 bgColorPrimary has-text-white p-2">Cajas por 20 unidades</p>
                  <p class="is-size-4 mt-5 bgColorPrimary has-text-white p-2">Caja Maestra de 12 cajas por 4 unidades</p>
                </div>
               <div class="ml-5 mr-5 mb-5">
                  <p class="is-size-4 mt-5 bgColorPrimary has-text-white p-2"> Línea económica ¡Para grandes festejos! </p>
                  <p class="is-size-4 mt-5 bgColorPrimary has-text-white p-2">Super Medallón por 40 und (115Gs)</p>
                  <p class="is-size-4 mt-5 bgColorPrimary has-text-white p-2">Medallón Parrillero por 60 und (83gs)</p>
                  <p class="is-size-4 mt-5 bgColorPrimary has-text-white p-2">Medallón por 60 und (70gs)</p>
                </div>                              

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Slider',
  data () {
    return {
        progress: true,
        progressType: 'is-primary'
    }
  },
   computed: {
    ...mapState('brand', ['brand'])
  }
}
</script>
