<template>
  <div class="home">
    <!-- Slider -->
    <Slider  id="inicio"  style="z-index:0!important;position:relative;display:block"/>
    <section id="promos"></section>
    <div class="bgColorPrimary width100 pt-5 pb-5 pl-2 pr-2 ">
      <h2 class="is-size-3-tablet is-size-3-mobile has-text-white has-text-center letterSpacingShort fontUpperCase fontWeightUltraBlack scale-in-center fontPrimary fontWeightRegular">
          ¿Tenes un comercio? Comunicate y recibí nuestras hamburguesas
      </h2>
    </div>
    <SeparatorImagesThree class="is-hidden" />
    <img src="@/assets/img/portadaM.webp" class="is-hidden-tablet is-block width100">
    <img src="@/assets/img/portada.webp" class="is-hidden-mobile is-block width100">
    <vue-particles
    color="#fe0000"
    :particle-opacity="0.4"
    :particles-number="100"
    shape-type="circle"
    :particle-size="4"
    :line-opacity="0"
    :lines-distance="10"
    :move-speed="3"
    class="particlesGeneral"
    />
    <section id="productos"></section>
    <Products />
    <Sell />
               <div class="columns is-multiline" style="overflow:none!important;margin-bottom:0px!important">
      <div class="column is-4 p-0">
        <img src="@/assets/img/fotos/1.jpg" class="width100 is-block">
      </div>
      <div class="column is-4 p-0">
        <img src="@/assets/img/fotos/2.jpg" class="width100 is-block">
      </div>
      <div class="column is-4 p-0">
        <img src="@/assets/img/fotos/3.jpg" class="width100 is-block">
      </div> 
    </div>
    <section id="comments"></section>
    <Comments />
     <section id="cumpleanos"></section>
    <SeparatorSectionTwo
      :titleOne="'PRECIO + NUTRICIÓN + SABOR'" 
      :titleOneColor="'has-text-white fontWeightBlack fontUpperCase fontItalic'"
      :titleTwo="''"
      :titleTwoColor="'has-text-white fontWeightBlack'"
      :titleThreeColor="'has-text-white fontWeightBlack'"
      :subtitleOne="''"
      :subtitleColor="'has-text-white'"
      :subtitleTwo="'.'"
      :bgSeparator="'bgColorPrimary pt-6'"
    />   
    <div class="bgColorPrimary pb-6">
        <font-awesome-icon icon="arrow-down" class="has-text-white is-size-3 ml-2 arrowAnimation" />
        <font-awesome-icon icon="arrow-down" class="has-text-white is-size-3 ml-2 arrowAnimation" />
        <font-awesome-icon icon="arrow-down" class="has-text-white is-size-3 ml-2 arrowAnimation" />
        <font-awesome-icon icon="arrow-down" class="has-text-white is-size-3 ml-2 arrowAnimation" />
        <font-awesome-icon icon="arrow-down" class="has-text-white is-size-3 ml-2 arrowAnimation" />
        <br><br>
    </div>

    <div class="container-fluid">
      <div class="container" stylE>
          <img src="@/assets/img/fiesta.webp" class="width500 centerBlock" style="position:relative;bottom:60px">
          <h1 
           style="position:relative;bottom:60px"
          class="titleBigE mb-1 colorPrimary fontWeightBlack fontMoreBlack has-text-center fontUpperCase scale-in-center fontPrimary">
            CONTACTANOS PARA SABER MÁS
          </h1>
          <h2 class="is-size-2-tablet pl-2 pr-2 is-size-4-mobile has-text-dark  fontWeightRegular has-text-center scale-in-center fontPrimary mt-0">
            Envianos la documentación para que podamos comprobar que tenes un negocio y comenzá a vender nuestro producto accediendo a grandes beneficios y al mejor precio.
            <span class="fontWeightBlack">¡Tus clientes van a estar super contentos!</span>
            <br><br>
             <span class=" fontWeightBlack pl-2 pr-2">
              Presentación:
             </span>
             <br>
            <span class=" fontWeightBlack pl-2 pr-2">Super Medallón caja x 40</span> <span class="is-hidden-tablet"><br></span> <span class="">la unidad de 115Gs</span>
            <br> <span class="is-hidden-tablet"><br></span>
            <span class="fontWeightBlack pl-2 pr-2">Medallón Parrillero caja x 60</span> <span class="is-hidden-tablet"><br></span> <span class="">la unidad de 83gs</span> 
            <br> <span class="is-hidden-tablet"><br></span>
            <span class="fontWeightBlack pl-2 pr-2">Medallón caja x 60</span> <span class="is-hidden-tablet"><br></span><span class="">la unidad de 70gs </span> 

          </h2><br> 
          <h2 class="mt-4 is-size-3-tablet is-size-4-mobile has-text-dark  fontWeightRegular has-text-center scale-in-center fontPrimary mt-0">
            Tu consulta no molesta
          </h2>
          <br><br>     <br><br>
      </div>
    </div>
    <div>

    <SeparatorSection
    :titleOne="'Trabajamos para brindarte'" 
    :titleOneColor="'has-text-white fontWeightBlack fontUpperCase is-size-1-tablet is-size-3-mobile'"
    :titleTwo="''"
    :titleTwoColor="'colorPrimary fontWeightBlack is-size-1-tablet is-size-3-mobile'"
    :titleThree="''"
    :titleThreeColor="'has-text-white fontWeightBlack'"
    :subtitleOne="'SERVICIO - CALIDAD - ATENCIÓN'"
    :subtitleTwo="''"
    :subtitleColor="'has-text-white'"
    :bgSeparator="'bgColorBlack'"
    />
    <SeparatorImages />

    <section id="porma"></section>


  
 

    <section id="distri"></section>
    <div class="container-fluid pt-5 pb-2">
      <br><br><br>
      <div class="container pl-5 pr-5">
            <img src="@/assets/img/logo.png"
            class="mx-auto is-block mt-4" style="width:250px"
            > 
      <h1
        class="is-size-1-tablet is-size-3-mobile has-text-centered has-text-dark fontWeightBlack fontMoreBlack mt-6 mb-5  pt-1 p-4 fontUpperCase">
        ¿Te interesa distribuir?
      </h1>
      <h1
        class="is-size-2-tablet is-size-3-mobile has-text-dark fontWeightBlack fontMoreBlack mt-3 mb-5  pt-1 p-4 ">
         <font-awesome-icon icon="truck" class="colorSecondary" /> <span class="is-hidden-tablet"><br></span>Buscamos distribuidores
      </h1>   
      <a href="#contacto">
        <button
          class="button3dSuccess p-4 mx-auto is-block widthMax300">
              <img src="@/assets/img/whatsapp.webp" class="imgWp is-hidden">
              <p class="is-inline-block is-size-4 has-text-white fontWeightBlack fontUpperCase letterSpacingShort">Contactar</p>
        </button>
      </a>
                  
      </div> 
    </div>

   <SeparatorImagesTwo />
   <section id="contacto"></section>
   <br>   <br>   <br>
    <Form />

</div>      
    <Footer />
    <Whatsapp />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Slider from '@/components/sections/Slider'
import Sell from '@/components/sections/Sell'
import SeparatorSection from '@/components/sections/SeparatorSection'
import SeparatorSectionTwo from '@/components/sections/SeparatorSectionTwo'
import Comments from '@/components/sections/Comments'
import SeparatorImages from '@/components/sections/SeparatorImages'
import SeparatorImagesTwo from '@/components/sections/SeparatorImagesTwo'
import SeparatorImagesThree from '@/components/sections/SeparatorImagesThree'
import Products from '@/components/sections/Products'
import Footer from '@/components/Footer'
import Form from '@/components/Form'
import Whatsapp from '@/components/Whatsapp'
export default {
  name: 'Home',
  components: {
    Slider,
    SeparatorSection,
    SeparatorSectionTwo,
    Comments,
    SeparatorImages,
    SeparatorImagesTwo,
    SeparatorImagesThree,
    Products,
    Sell,
    Form,
    Footer,
    Whatsapp
  },
    computed: {
    ...mapState('brand', ['brand'])
  },
    methods: {
      goWhatsapp () {
        window.location.href=`https://api.whatsapp.com/send?phone=${this.brand.phone}?text=Hola! Me contacto desde la web y quiero hacer un pedido`
      },
      goWhatsapp2 () {
        window.location.href=`https://api.whatsapp.com/send?phone=${this.brand.phone}?text=Hola! Me contacto desde la web y me interesa distribuir`
      },
  }
}
</script>
